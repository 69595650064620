import React from 'react';
import { Grid, Typography, Link, Button, IconButton, FormHelperText } from '@material-ui/core';
import { InputRichTextField, SelectMultipleField, CheckboxField, InputField, SelectField } from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { DEIFields, TEXT_PREFER_NOT_TO_SAY } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { FormMode } from '../models/FormMode';
import styles from '../styles';
import { EDIQuestion02 } from '../models/DataTypes';

export default function ReviewDiversityDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    editable,
    mode,
    getChoices,
    getField,
    formField: {
      div1,
      div2,
      div2a,
      div2b,
      div2c,
      div2d,
      div2e,
      div2f,
      div2g,
      div2h,
      div2i,
      div3,
      div4,
      div5,
      div6,
      div7,
      div7txt,
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

  const fieldDiv1 = props.init && getField("EDIQuestion01");
  const fieldDiv2 = props.init && getField("EDIQuestion02");
  const fieldDiv2a = props.init && getField("EDIQuestion02a");
  const fieldDiv2b = props.init && getField("EDIQuestion02b");
  const fieldDiv2c = props.init && getField("EDIQuestion02c");
  const fieldDiv2d = props.init && getField("EDIQuestion02d");
  const fieldDiv2e = props.init && getField("EDIQuestion02e");
  const fieldDiv2f = props.init && getField("EDIQuestion02f");
  const fieldDiv2g = props.init && getField("EDIQuestion02g");
  const fieldDiv2h = props.init && getField("EDIQuestion02h");
  const fieldDiv2i = props.init && getField("EDIQuestion02i");
  const fieldDiv3 = props.init && getField("EDIQuestion03");
  const fieldQ8a = props.init && getField(DEIFields.Question4);
  const fieldQ8b = props.init && getField(DEIFields.Question5);
  const fieldQ8c = props.init && getField(DEIFields.Question6);
  const fieldQ9 = props.init && getField(DEIFields.Question9);
  const fieldQ9txt = props.init && getField(DEIFields.Question9txt);

  const diversityIntro = props.init && props.init.formTexts.DiversityIntro;
  const diversityQ1Desc = props.init && props.init.formTexts.DiversityQ1;
  const diversityQ2Desc = props.init && props.init.formTexts.DiversityQ2;
  const diversityQ3Desc = props.init && props.init.formTexts.DiversityQ3;

  const div2Choices = props.init && getChoices("EDIQuestion02") && getChoices("EDIQuestion02");
  const div2aChoices = props.init && getChoices("EDIQuestion02a") && getChoices("EDIQuestion02a");
  const div2bChoices = props.init && getChoices("EDIQuestion02b") && getChoices("EDIQuestion02b");
  const div2cChoices = props.init && getChoices("EDIQuestion02c") && getChoices("EDIQuestion02c");
  const div2dChoices = props.init && getChoices("EDIQuestion02d") && getChoices("EDIQuestion02d");
  const div2eChoices = props.init && getChoices("EDIQuestion02e") && getChoices("EDIQuestion02e");
  const div2fChoices = props.init && getChoices("EDIQuestion02f") && getChoices("EDIQuestion02f");
  const div2gChoices = props.init && getChoices("EDIQuestion02g") && getChoices("EDIQuestion02g");
  const div2hChoices = props.init && getChoices("EDIQuestion02h") && getChoices("EDIQuestion02h");
  const div2iChoices = props.init && getChoices("EDIQuestion02i") && getChoices("EDIQuestion02i");



  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }

  const modules = {
    toolbar: null
  };

  const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.diversitydetails}>
      {(mode === FormMode.Review) && <Grid container>
        <Grid item xs={12}>
          <List>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="diversitydetails-content"
                id="diversitydetails-header"
              >
                {title}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText className={classesFields.listItem} primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1} /></ListItem>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText className={classesFields.listItem} primary={`${div2.label} ${fieldDiv2.description}`} secondary={''} /></ListItem>
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Communities) > -1 && <ListItem button><ListItemText className={classesFields.listItem} primaryTypographyProps={{color:'#ccc'}} style={{ paddingLeft: 8 }} primary={fieldDiv2a.description} secondary={formValues.div2a} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Disabled) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2b.description} secondary={formValues.div2b} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Faith) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2c.description} secondary={formValues.div2c} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.LGBT) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2d.description} secondary={formValues.div2d} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Migrants) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2e.description} secondary={formValues.div2e} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Older) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem}  style={{ paddingLeft: 8 }} primary={fieldDiv2f.description} secondary={formValues.div2f} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Children) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2g.description} secondary={formValues.div2g} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.People) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2h.description} secondary={formValues.div2h} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Women) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2i.description} secondary={formValues.div2i} /></ListItem>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}></Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div4.label} ${fieldQ8a.description}`} secondary={formValues.div4.join(', ')} /></ListItem>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div5.label} ${fieldQ8b.description}`} secondary={formValues.div5.join(', ')} /></ListItem>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div6.label} ${fieldQ8c.description}`} secondary={formValues.div6 && formValues.div6.length > 0 && formValues.div6.join(', ')} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${div7.label} ${fieldQ9.description}`} secondary={formValues.div7 ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div7txt} modules={modules} /></div> : "No"} /></ListItem></div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </List>
        </Grid>
      </Grid>}

      
      {(mode == FormMode.Stage) &&
        <> {title}
          <List>
          <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText className={classesFields.listItem} primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1} /></ListItem>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText  className={classesFields.listItem} primary={`${div2.label} ${fieldDiv2.description}`} secondary={''} /></ListItem>
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Communities) > -1 && <ListItem button><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2a.description} secondary={formValues.div2a} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Disabled) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2b.description} secondary={formValues.div2b} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Faith) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2c.description} secondary={formValues.div2c} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.LGBT) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2d.description} secondary={formValues.div2d} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Migrants) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2e.description} secondary={formValues.div2e} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Older) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2f.description} secondary={formValues.div2f} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Children) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2g.description} secondary={formValues.div2g} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.People) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2h.description} secondary={formValues.div2h} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Women) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText className={classesFields.listItem} style={{ paddingLeft: 8 }} primary={fieldDiv2i.description} secondary={formValues.div2i} /></ListItem>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}></Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div4.label} ${fieldQ8a.description}`} secondary={formValues.div4.join(', ')} /></ListItem>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div5.label} ${fieldQ8b.description}`} secondary={formValues.div5.join(', ')} /></ListItem>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div6.label} ${fieldQ8c.description}`} secondary={formValues.div6 && formValues.div6.length > 0 && formValues.div6.join(', ')} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${div7.label} ${fieldQ9.description}`} secondary={formValues.div7 ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div7txt} modules={modules} /></div> : "No"} /></ListItem></div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
          </List></>
      }
    </div >
  )
}