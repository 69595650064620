export default {
  formId: 'eligibilityCheckForm',
  formField: {
    regCharity: {
      name: 'regCharity',
      label: 'Are you a registered charity or Community Interest Company (CIC)? *'
    },
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: 'What was your total published income for the last financial year (£) *',
      requiredErrorMsg: 'What was your total published income.. is required'
    },
    eligibility03: {
      name: 'eligibility03',
      label: 'Does your organisation have at least one year of published accounts? *'
    },
    eligibility14: {
      name: 'eligibility14',
      label: 'Does your organisation deliver the majority of its work within the following geographical areas – Greater London, Bath and North East Somerset, Berkshire, Buckinghamshire, Hertfordshire, Oxfordshire, Surrey, Kent, Hampshire, West Sussex, and the City of Birmingham? *'
    },
    eligibility04: {
      name: 'eligibility04',
      label: 'Was your published income for the last financial year between £100,000 and £1.5m? *'
    },
    eligibility05: {
      name: 'eligibility05',
      label: 'Is your organisation focused on building leadership skills in young people affected by racist or Islamophobic violence? *'
    },
    eligibility06: {
      name: 'eligibility06',
      label: 'Are you able to demonstrate that young people have a clear role and voice at every level of the organisation? *'
    },
    eligibility07: {
      name: 'eligibility07',
      label: 'Are you applying for funding towards organisational development work and not frontline delivery? *'
    },
    eligibility08: {
      name: 'eligibility08',
      label: 'Are you applying for funding for over three years? *'
    },
    eligibility09: {
      name: 'eligibility09',
      label: 'Are you requesting no more than 20% of your overall turnover in any one year? *'
    },
    eligibility10: {
      name: 'eligibility10',
      label: 'Does your organisation have a safeguarding policy? *'
    },
  
    eligibility11: {
      name: 'eligibility11',
      label: 'Are you applying for funding towards work that has not yet started?'
    },
    eligibility12: {
      name: 'eligibility12',
      label: 'Does your organisation only operate in the UK?'
    },
    eligibility13: {
      name: 'eligibility13',
      label: 'If your application is successful, would it be the first time that you are receiving support from the Berkeley Foundation’s Resilience Fund?'
    },
    charitableWorkArea: {
      name: 'charitableWorkArea',
      label: 'Please select the areas you operate in *',
      requiredErrorMsg: 'Please select the areas you operate in is required'
    },

  }
};
