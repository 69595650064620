import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Link,
  Grid
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios";
import useStyles from '../styles';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import validationSchema2 from './models/validationSchema2';
import nondocsvalidationSchema from './models/nondocsvalidationSchema';
import formModel from './models/formModel2';
import { Success, PersonalDetails, OrganisationDetails, ProjectDetails, Review2, Documents, AccessibilityDetails, ReviewDiversityDetails,DiversityDetails2,DiversityDetails3 } from './sections';
import Errors from '../Errors';
import ReCAPTCHA from "react-google-recaptcha";
import { FR04Stage1Context } from '../../common/FR04Stage1Context';
import { FormMode } from './models/FormMode';
//import formInitialValues from './models/formInitialValues';

const STEPS = {
  Accessibility: 0,
  EquityDiversityandInclusion: 1,
  Stage1: 2,
  AboutOrg: 3,
  AboutProject: 4,
  SupportDocs: 5,
  ReviewSubmit: 6,
  Success: 7,
  SaveforLaterSuccess: 8
}

const steps = ['Accessibility', 'Diversity, Equity and Inclusion', 'Stage 1 Application', 'About your organisation', 'About your organisational resilience needs','Budget', 'Review and Submit'];
const { formId, formField } = formModel;
const SKIP_STEP = 3;
function renderStepContent(step, props) {
  switch (step) {
    case STEPS.Accessibility:
      return <AccessibilityDetails formField={formField} {...props} mode={FormMode.Stage} />;
    case STEPS.EquityDiversityandInclusion:
      return <ReviewDiversityDetails formField={formField} {...props} mode={FormMode.Stage} />;
    case STEPS.Stage1:
      return <PersonalDetails formField={formField} {...props} mode={FormMode.Stage} />;
    case STEPS.AboutProject:
      return <ProjectDetails formField={formField} {...props} mode={FormMode.Edit} />;
    case STEPS.AboutOrg:
      return <OrganisationDetails formField={formField} {...props} mode={FormMode.Stage} />;
    case STEPS.SupportDocs:
      return <Documents formField={formField} {...props} mode={FormMode.Edit} />;
    case STEPS.ReviewSubmit:
      return <Review2 formField={formField} {...props} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function MainForm042(props) {
  const { fr04Stage1 } = useContext(FR04Stage1Context);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(3);
  const currentValidationSchema = fr04Stage1 && fr04Stage1.docsExists ? nondocsvalidationSchema[activeStep] : validationSchema2[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const recaptchaRef = useRef();
  const config = fr04Stage1.config;

  const [serverErrors, setServerErrors] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [skipped, setSkipped] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [saveforLater, setSaveforLater] = useState(false);
  const  docsExists = fr04Stage1 && fr04Stage1.docsExists;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const getChoices = (name) => {
    let { fields } = fr04Stage1.init;
    let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
    let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
    return choices;
  }

  const getField = (name) => {
    let { fields } = fr04Stage1.init;
    let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
    return field;
  }

  async function saveForLaterForm(values, actions, attachments) {
    let token = '';
    if (fr04Stage1.init.humanEnabled) {
      if (recaptchaRef.current) {
        token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
      }
    }
    setServerErrors([]);
    let currentCycle = fr04Stage1.currentCycle;

    const postData = {
      ...values,
      cycle: currentCycle,
      initData: fr04Stage1.init,
      token,
      itemId: fr04Stage1.stage.FundingRunItemId,
      attachments: attachments,
      isSaveforlater: true,
      saveforlaterSendEmail: true,
      humanEnabled: fr04Stage1.init.humanEnabled
    };
    let apiUrl = `${config.ApiUrl}/update${currentCycle.id}`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
    const response = await fetch(apiUrl,
      {
        body: JSON.stringify(postData),
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${config.ApiToken}`
        }
      })
      .catch(error => {
        setServerErrors([{ message: error }]);
        actions.setSubmitting(false);
      });
    const result = await response.json();
    console.log('result', result);
    if (result) {
      if (result.data) {
        uploadFiles(actions, attachments, result.data.BGApplication);
      }
    }
    else if (result.errors) {
      setServerErrors(result.errors);
      actions.setSubmitting(false);
    } else if (result.error) {
      setServerErrors([{ message: result.error }]);
      actions.setSubmitting(false);
    }
  }

  async function submitForm(values, actions, attachments) {
    let token = '';
    if (fr04Stage1.init.humanEnabled) {
      if (recaptchaRef.current) {
        token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
      }
    }
    setServerErrors([]);
    let currentCycle = fr04Stage1.currentCycle;
    const postData = {
      ...values,
      cycle: currentCycle,
      initData: fr04Stage1.init,
      token,
      itemId: fr04Stage1.stage.FundingRunItemId,
      attachments: attachments,
      stage: "Stage 2 Submitted",
      humanEnabled: fr04Stage1.init.humanEnabled

    };
    let apiUrl = `${config.ApiUrl}/update${currentCycle.id}`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
    const response = await fetch(apiUrl,
      {
        body: JSON.stringify(postData),
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${config.ApiToken}`
        }
      })
      .catch(error => {
        setServerErrors([{ message: error }]);
        actions.setSubmitting(false);
      });
    const result = await response.json();
    console.log('result', result);
    if (result) {
      if (result.data) {
        uploadFiles(actions, attachments, result.data.BGApplication);
      }
    }
    else if (result.errors) {
      setServerErrors(result.errors);
      actions.setSubmitting(false);
    } else if (result.error) {
      setServerErrors([{ message: result.error }]);
      actions.setSubmitting(false);
    }

  }

  function uploadFiles(actions, attachments, appRef) {
    if (skipped) {
      setActiveStep(5);
      setServerErrors([]);
      actions.setSubmitting(false);
      return;
    }
    attachments.map(attachment => {
      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("fileName", attachment.name);
      formData.append("init", fr04Stage1.init);
      formData.append("siteId", fr04Stage1.init.siteId);
      formData.append("listId", fr04Stage1.init.listId);
      formData.append("docLibId", fr04Stage1.init.docLibId);
      formData.append("appRef", appRef);
      formData.append("cycle", fr04Stage1.currentCycle.cycle);

      let apiUrl = config.ApiUrl;
      try {
        const response = axios.post(
          `${apiUrl}/upload`,
          formData
        );
        const result = response.json();

        if (result) {
          //dont wait, update progress?  
        }
      } catch (ex) {
        console.log(ex);
        // setServerErrors([{message:ex}]);
        //actions.setSubmitting(false);
      }
    });
    const activeStep = saveforLater ? STEPS.SaveforLaterSuccess : STEPS.Success;
    setActiveStep(activeStep);
    setServerErrors([]);
    actions.setSubmitting(false);
  };

  function handleSubmit(values, actions) {
    if (saveforLater) {
      saveForLaterForm(values, actions, attachments);
      return;
    } else if (isLastStep) {
      submitForm(values, actions, attachments);
    } else {
      let nextStep = activeStep + 1;
      setActiveStep(nextStep);
      actions.setTouched({});
      actions.setSubmitting(false);
      if (nextStep == STEPS.SupportDocs && !docsExists) {
        setDisableNext(attachments.length == 0);
      }
    }
  }

  function handleBack() {
    let prevStep = activeStep - 1;
    if (!docsExists && prevStep == STEPS.SupportDocs) {
      setDisableNext(attachments.length == 0);
    } else {
      setDisableNext(false);
    }
    setActiveStep(prevStep);
  }

  const onEdit = (step) => {
    setActiveStep(step);
  }

  const isStepOptional = (step) => {
    return step === SKIP_STEP;
  };

  const handleAttachments = (array) => {
    array.forEach((item, index) => {
      attachments.push(item);
    });
    setAttachments(attachments);
    setSkipped(false);
    setDisableNext(!docsExists && attachments.length == 0);
  }

  const onFilesRemoved = (array) => {
    setAttachments(array);
    setSkipped(false);
    setDisableNext(!docsExists && array.length == 0);
  }

  const formProps = { steps, stepId: activeStep, init: fr04Stage1.init, onEdit, getChoices, getField, handleAttachments, attachments, onFilesRemoved, skipped, stageDocs: fr04Stage1.stage ? fr04Stage1.stage.docs : null };
  return (
    <>
      <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
        {fr04Stage1.currentCycle.title}
      </Typography>
      {(activeStep === STEPS.SaveforLaterSuccess || activeStep == STEPS.Success) ? (
        <Success {...formProps} textKey={activeStep == STEPS.SaveforLaterSuccess ? "SaveforLaterSuccessText" : "SuccessStage2"} />
      ) :
        (
          <React.Fragment>
            {activeStep < STEPS.ReviewSubmit && <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => (
                <Step key={label}>
                  {fr04Stage1.stage && (index < STEPS.AboutProject) ? <StepLabel><Link component="button" variant="body2" color="default" onClick={() => { onEdit(index) }}>{label}</Link></StepLabel> : <StepLabel>{label}</StepLabel>}
                </Step>
              ))}
            </Stepper>}
            <Formik
              initialValues={fr04Stage1.formInitialValues}
              validationSchema={activeStep > 2 && !saveforLater && currentValidationSchema}
              onSubmit={handleSubmit}
              style={{ width: '100%' }}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {renderStepContent(activeStep, formProps)}

                  <div className={classes.buttons} >
                    <div className={classes.wrapper}>
                      {activeStep !== STEPS.Accessibility && (
                        <Button onClick={handleBack} startIcon={<ArrowLeftIcon />} className={classes.buttonBack} variant="contained" color="default">
                          Back
                        </Button>
                      )}
                      {(activeStep > STEPS.Stage1) && <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => { setSaveforLater(true); }}
                        className={classes.buttonSaveforLater}
                        endIcon={<SaveIcon />}
                      >
                        Save for later
                      </Button>}
                      <Button
                        disabled={disableNext}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonNext}
                        endIcon={isLastStep ? <SendIcon /> : <ArrowRightIcon />}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="reCaptcha">
                    {(activeStep == STEPS.ReviewSubmit || saveforLater) && fr04Stage1.init.humanEnabled && <ReCAPTCHA
                      sitekey={config.RecaptchaKey}
                      ref={recaptchaRef}
                      size="invisible"
                    />
                    }
                  </div>
                </Form>
              )}
            </Formik>
            {serverErrors && serverErrors.length > 0 && <Errors errors={serverErrors} />}
          </React.Fragment>
        )}
    </>
  );
}