import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { TEXT_NONE_OF_THE_ABOVE } from '../common/DataTypes';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  TextField
} from '@material-ui/core';
import useStyles from './styles';
import SimplePopover from './SimplePopover';

function SelectMultipleToggleField(props) {
  const classes = useStyles();
  const { label, data, filterOption, description, description2, ...rest } = props;
  const [field, meta, includeNone] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const filter = filterOption || TEXT_NONE_OF_THE_ABOVE;
  //const data2 = includeNone === true ? data : data.filter((item)=>item.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1);
  //const data3 = data2 && data2.map(item=>{ return {label:item, value:item}});
  const data3 = data && data.length > 0 && data.map(item => { return { label: item, value: item } });
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const handleChange = (event) => {
    let selectedValue = event.target.value;
    if (selectedValue.indexOf(filter) > -1) {
      if (field.value.indexOf(filter) > -1) {
        event.target.value = selectedValue.filter((item) => item.indexOf(filter) < 0);
      } else {
        event.target.value = selectedValue.filter((item) => item.indexOf(filter) > -1);
      }

      field.onChange(event);
    } else {
      field.onChange(event);
    }
  };

  function _renderDescriptionText() {
    if (description2) {
      return <p style={{ fontStyle: 'italic', color: '#0000008a', lineHeight: 1.66, letterSpacing: 0.033, margin: '0' }}>{description2}</p>;
    }
  }

  return (
    <FormControl {...rest} error={isError} className={props.extended ? classes.multiSelectExtended : classes.multiSelect}>
      <InputLabel style={{ ...props.labelStyle }} className={props.labelClass ? classes.labelMultiline : classes.label}>{label}</InputLabel>
      {description && <SimplePopover {...props} content={description} />}
      {data3 ? <Select {...field} multiple
        style={{ fontSize: 14 }}
        autoComplete="off"
        renderValue={(selected) => (selected).join(', ')}
        onChange={handleChange}>
        {data3 && data3.map((item, index) => (
          <MenuItem key={index} value={item.value} style={{ fontSize: 14 }}>
            <div style={{ display: 'flex', width: '-webkit-fill-available' }}><span style={{ whiteSpace: 'normal', width: '80%' }}>{item.label}</span>
              <div style={{ float: 'right', position: 'absolute', right: 18, top: -2 }}>
                <Switch checked={selectedValue && selectedValue.indexOf(item.value) > -1} />
                {selectedValue && selectedValue.indexOf(item.value) > -1 && item.value.indexOf('(none)') != 0 ? <span className='text selected'>Yes</span> : <span className='text'>No</span>}
              </div>
            </div>
          </MenuItem>
        ))}
      </Select> :
        <TextField
          className={classes.singleTextExtended}
          type="text"
          autoComplete="off"
          disabled
          {...field}
          {...rest}
        />
      }
      {_renderDescriptionText()}
      {_renderHelperText()}
    </FormControl>
  );
}

SelectMultipleToggleField.defaultProps = {
  data: []
};

SelectMultipleToggleField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectMultipleToggleField;
