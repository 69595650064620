import React from 'react';
import { Grid, Typography, Link, Button, IconButton, FormHelperText } from '@material-ui/core';
import { InputRichTextField, SelectMultipleField, CheckboxField, InputField, SelectField } from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { SelectType, TEXT_PREFER_NOT_TO_SAY } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { FormMode } from '../models/FormMode';
import styles from '../styles';
import { transform } from 'lodash';
import { EDIQuestion02 } from '../models/DataTypes';

export default function DiversityDetails1(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    editable,
    mode,
    getChoices,
    getField,
    formField: {
      div1,
      div2,
      div2a,
      div2b,
      div2c,
      div2d,
      div2e,
      div2f,
      div2g,
      div2h,
      div2i,
      div3,
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]} (Part 1 of 2)</Typography>;

  const fieldDiv1 = props.init && getField("EDIQuestion01");
  const fieldDiv2 = props.init && getField("EDIQuestion02");
  const fieldDiv2a = props.init && getField("EDIQuestion02a");
  const fieldDiv2b = props.init && getField("EDIQuestion02b");
  const fieldDiv2c = props.init && getField("EDIQuestion02c");
  const fieldDiv2d = props.init && getField("EDIQuestion02d");
  const fieldDiv2e = props.init && getField("EDIQuestion02e");
  const fieldDiv2f = props.init && getField("EDIQuestion02f");
  const fieldDiv2g = props.init && getField("EDIQuestion02g");
  const fieldDiv2h = props.init && getField("EDIQuestion02h");
  const fieldDiv2i = props.init && getField("EDIQuestion02i");
  const fieldDiv3 = props.init && getField("EDIQuestion03");

  const diversityIntro = props.init && props.init.formTexts.DiversityIntro;
  const diversityQ1Desc = props.init && props.init.formTexts.DiversityQ1;
  const diversityQ2Desc = props.init && props.init.formTexts.DiversityQ2;
  const diversityQ3Desc = props.init && props.init.formTexts.DiversityQ3;

  const div2Choices = props.init && getChoices("EDIQuestion02") && getChoices("EDIQuestion02");
  const div2aChoices = props.init && getChoices("EDIQuestion02a") && getChoices("EDIQuestion02a");
  const div2bChoices = props.init && getChoices("EDIQuestion02b") && getChoices("EDIQuestion02b");
  const div2cChoices = props.init && getChoices("EDIQuestion02c") && getChoices("EDIQuestion02c");
  const div2dChoices = props.init && getChoices("EDIQuestion02d") && getChoices("EDIQuestion02d");
  const div2eChoices = props.init && getChoices("EDIQuestion02e") && getChoices("EDIQuestion02e");
  const div2fChoices = props.init && getChoices("EDIQuestion02f") && getChoices("EDIQuestion02f");
  const div2gChoices = props.init && getChoices("EDIQuestion02g") && getChoices("EDIQuestion02g");
  const div2hChoices = props.init && getChoices("EDIQuestion02h") && getChoices("EDIQuestion02h");
  const div2iChoices = props.init && getChoices("EDIQuestion02i") && getChoices("EDIQuestion02i");


  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }

  const modules = {
    toolbar: null
  };

  const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.diversitydetails}>
      {(mode === FormMode.Review) && <Grid container>
        <Grid item xs={12}>
          <List>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="diversitydetails-content"
                id="diversitydetails-header"
              >
                {title}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText className={classesFields.listItem} primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1} /></ListItem>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText className={classesFields.listItem} primary={`${div2.label} ${fieldDiv2.description}`} secondary={''} /></ListItem>
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Communities) > -1 && <ListItem button><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2a.description} secondary={formValues.div2a} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Disabled) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2b.description} secondary={formValues.div2b} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Faith) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2c.description} secondary={formValues.div2c} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.LGBT) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2d.description} secondary={formValues.div2d} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Migrants) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2e.description} secondary={formValues.div2e} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Older) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2f.description} secondary={formValues.div2f} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Children) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2g.description} secondary={formValues.div2g} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.People) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2h.description} secondary={formValues.div2h} /></ListItem>}
                      {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Women) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2i.description} secondary={formValues.div2i} /></ListItem>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}></Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </List>
        </Grid>
      </Grid>}

      {(mode === FormMode.Stage) &&
        <>
          {title}
          <List>
            <Grid item xs={12} sm={12}>
              <ListItem button><ListItemText className={classesFields.listItem} primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1 || ''} /></ListItem>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ListItem button><ListItemText className={classesFields.listItem} primary={`${div2.label} ${fieldDiv2.description}`} secondary={''} /></ListItem>
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Communities) > -1 && <ListItem button><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2a.description} secondary={formValues.div2a} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Disabled) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2b.description} secondary={formValues.div2b} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Faith) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2c.description} secondary={formValues.div2c} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.LGBT) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2d.description} secondary={formValues.div2d} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Migrants) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2e.description} secondary={formValues.div2e} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Older) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2f.description} secondary={formValues.div2f} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Children) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2g.description} secondary={formValues.div2g} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.People) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2h.description} secondary={formValues.div2h} /></ListItem>}
              {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Women) > -1 && <ListItem style={{ padding: '0 0 0 16px' }}><ListItemText style={{ paddingLeft: 8 }} primary={fieldDiv2i.description} secondary={formValues.div2i} /></ListItem>}
            </Grid>
            <Grid item xs={12} sm={12}>
              <ListItem button><ListItemText className={classesFields.listItem} primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3} /></ListItem>
            </Grid>
          </List>
        </>
      }
      {
        (mode === FormMode.Edit) && <Grid container spacing={3}>
          <Grid item xs={12}>
            {title}
            <div dangerouslySetInnerHTML={{ __html: diversityIntro }}></div>
          </Grid>
          <Grid item xs={12}>
            <InputField
              name={div1.name}
              label={`${div1.label} ${fieldDiv1.description}`}
              fullWidth
              color="secondary" variant="outlined"
              description={diversityQ1Desc}
              placeholder='Please enter the name of the neighbourhood, area, county, region or country, as applicable, using a semi-colon to separate each area. Refer to (i) for examples'
              multiline
              extended
            />
          </Grid>
          <Grid item xs={12} className="selectRadio" sm={12}>
            <SelectMultipleField
              name={div2.name}
              label={`${div2.label} ${fieldDiv2.description}`}
              labelStyle={{ 'transform': 'translate(14px, 11px) scale(1)' }}
              data={div2Choices}
              value={div2.value}
              fullWidth
              variant="outlined"
              color="secondary"
              description={diversityQ2Desc}
              placeholder=' You may select from multiple categories to reflect who your organisation is intended to support.'
              description2={formValues.div2.length > 0 ? 'Please select one sub-category below for each community you have selected. If you are supporting people identifying with multiple sub-categories, please select the first option in the drop-down list.' : 'Please select any category that you anticipate that the majority (75% or more) of people supported or benefiting from your work will identify with.'}
              extended
            />
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Communities) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 18 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2a.name}
                label={`${div2a.label} ${fieldDiv2a.description}`}
                data={div2aChoices}
                value={div2a.value}
                fullWidth
                color="secondary"
                SelectType={SelectType.RADIO}
                className={styles.div2a}
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Disabled) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2b.name}
                label={`${div2b.label} ${fieldDiv2b.description}`}
                data={div2bChoices}
                value={div2b.value}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Faith) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2c.name}
                label={`${div2c.label} ${fieldDiv2c.description}`}
                data={div2cChoices}
                value={div2c.value}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.LGBT) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2d.name}
                label={`${div2d.label} ${fieldDiv2d.description}`}
                data={div2dChoices}
                value={div2d.value}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Migrants) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2e.name}
                label={`${div2e.label} ${fieldDiv2e.description}`}
                data={div2eChoices}
                value={div2e.value}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Older) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2f.name}
                label={`${div2f.label} ${fieldDiv2f.description}`}
                data={div2fChoices}
                value={div2f.value}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Children) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2g.name}
                label={`${div2g.label} ${fieldDiv2g.description}`}
                data={div2gChoices}
                value={div2g.value || ['']}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.People) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2h.name}
                label={`${div2h.label} ${fieldDiv2h.description}`}
                data={div2hChoices}
                value={div2h.value || ['']}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}
            {formValues.div2 && formValues.div2.indexOf(EDIQuestion02.Women) > -1 && <Grid style={{ paddingLeft: 24, paddingTop: 12 }} item xs={12} className="selectRadio" sm={12}>
              <SelectField
                name={div2i.name}
                label={`${div2i.label} ${fieldDiv2i.description}`}
                data={div2iChoices}
                value={div2i.value || ['']}
                fullWidth
                color="secondary"
                withText
              />
            </Grid>}

          </Grid>
          <Grid item xs={12}>
            <InputField
              name={div3.name}
              label={`${div3.label} ${fieldDiv3.description}`}
              fullWidth
              color="secondary" variant="outlined"
              description={diversityQ3Desc}
              multiline
              placeholder={'Enter types of lived experience areas separated by semi-colon, refer to (i) for examples'}
              extended
            />
          </Grid>
        </Grid>
      }
    </div >
  )
}