import * as Yup from 'yup';
import formModel from './formModel2';
const {
  formField: {
    q16,
    q17,
    q18,
    q19,
    q20,
    q21,
    q22,
    q23,
    q24,
    q25,
    attachments
  }
} = formModel;


export default [
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({
    /* [q16.name]: Yup.string()
      .required(`${q16.requiredErrorMsg}`)
      .test('len', `${q16.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q17.name]: Yup.array().min(1, `${q17.requiredErrorMsg}`),
    [q18.name]: Yup.string()
      .required(`${q18.requiredErrorMsg}`)
      .test('len', `${q18.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }),
    [q19.name]: Yup.string()
      .required(`${q19.requiredErrorMsg}`)
      .test('len', `${q19.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }),
    [q20.name]: Yup.number()
      .required(`${q20.requiredErrorMsg}`)
      .positive(() => `Invalid number`),
    [q21.name]: Yup.number()
      .required(`${q21.requiredErrorMsg}`)
      .positive(() => `Invalid number`)
      .max(60000, `This field must not exceed £60000.`) */
  }),
  Yup.object().shape({
       /* [q22.name]: Yup.string()
        .required(`${q22.requiredErrorMsg}`)
        .test('len', `${q22.requiredErrorMsg}`, val => {
          if(val && val.indexOf('<p><br></p>')) {
            return true;
          }
        }
        ),
      [q23.name]: Yup.string()
        .required(`${q23.requiredErrorMsg}`)
        .test('len', `${q23.requiredErrorMsg}`, val => {
          if(val && val.indexOf('<p><br></p>')) {
            return true;
          }
        }
        ),
        [q24.name]: Yup.string()
        .required(`${q24.requiredErrorMsg}`)
        .test('len', `${q24.requiredErrorMsg}`, val => {
          if(val && val.indexOf('<p><br></p>')) {
            return true;
          }
        }
        ),
      [q25.name]: Yup.string()
        .required(`${q25.requiredErrorMsg}`)
        .test('len', `${q25.requiredErrorMsg}`, val => {
          if(val && val.indexOf('<p><br></p>')) {
            return true;
          }
        }
        ) */
  }),
  Yup.object().shape({
    //[attachments.name]: Yup.array().min(1, `${attachments.requiredErrorMsg}`),
  })
];
