export const EDIQuestion02 = {
    Communities: "Communities experiencing racial inequity",
    Disabled: "Disabled people",
    Faith: "Faith communities",
    LGBT: "LGBT+ people",
    Migrants: "Migrants",
    Older: "Older people (typically those aged 60 and over)",
    Children: "Children and young people (typically people aged 26 and under)",
    People: "People who are educationally or economically disadvantaged",
    Women: "Women and girls"
}