import React, { useEffect } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { SelectMultipleToggleField, SelectMultipleField, CheckboxField, InputAdornmentField, RowRadioButtonsGroup, InputRichTextField } from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import { FormMode } from '../models/FormMode';
import EditIcon from '@material-ui/icons/Edit';
import { TEXT_TOTAL_EXCEED, DEIFields } from '../../../common/DataTypes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '../fields/styles';

export default function DiversityDetails2(props) {
    const classes = useStyles();
    const classesFields = makeStyles();
    const { values: formValues } = useFormikContext();
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]} (Part 2 of 2)</Typography>;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const getdiv4Choices = (formValues) => {
        const div4Choices = formValues.div1.split(';').filter(choice => choice != '');
        let selected = [];
        formValues.div4 && formValues.div4.map(val => {
            if (div4Choices.indexOf(val) > -1) {
                selected.push(val);
            }
        });
        formValues.div4 = selected;
        return div4Choices;
    }

    const getdiv6Choices = (formValues) => {
        const div6Choices = formValues.div3.split(';').filter(choice => choice != '');
        let selected = [];
        formValues.div6 && formValues.div6.map(val => {
            if (div6Choices.indexOf(val) > -1) {
                selected.push(val);
            }
        });
        formValues.div6 = selected;
        return div6Choices;
    }

    const {
        editable,
        formField: {
            div4,
            div5,
            div6,
            div7,
            div7txt,
        },
        getChoices,
        getField,
        mode
    } = props;
    const fieldQ8a = props.init && getField(DEIFields.Question4);
    const fieldQ8b = props.init && getField(DEIFields.Question5);
    const fieldQ8c = props.init && getField(DEIFields.Question6);
    const div4Desc = props.init && props.init.formTexts.DiversityQ4;
    const div5Desc = props.init && props.init.formTexts.DiversityQ5;
    const div6Desc = props.init && props.init.formTexts.DiversityQ6;
    const div4Choices = getdiv4Choices(formValues);
    const div5Choices = formValues.div2;
    const div6Choices = getdiv6Choices(formValues);

    const diversityIntro2 = props.init && props.init.formTexts.DiversityIntro2;
    const diversityIntro3 = props.init && props.init.formTexts.DiversityIntro3;

    const fieldQ9 = props.init && getField(DEIFields.Question9);
    const fieldQ9txt = props.init && getField(DEIFields.Question9txt);
    const div7Desc = props.init && props.init.formTexts.Question9;
    const div7txtDesc = props.init && props.init.formTexts.Question9txt;



    const onEdit = (step) => {
        if (props.onEdit) {
            props.onEdit(step);
        }
    }

    const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
    return (
        <div className={classes.diversitydetails}>
            {(mode === FormMode.Review) && <Grid container>
                <Grid item xs={12}>
                    <List>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="diversitydetails-content"
                                id="diversitydetails-header"
                            >
                                {title}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <ListItem button><ListItemText className={classesFields.listItem} primary={`${div4.label} ${fieldQ8a.description}`} secondary={formValues.div4.join(', ')} /></ListItem>
                                            <ListItem button><ListItemText className={classesFields.listItem} primary={`${div5.label} ${fieldQ8b.description}`} secondary={formValues.div5.join(', ')} /></ListItem>
                                            <ListItem button><ListItemText className={classesFields.listItem} primary={`${div6.label} ${fieldQ8c.description}`} secondary={formValues.div6.join(', ')} /></ListItem>
                                            <ListItem button><ListItemText className={classesFields.listItem} primary={`${div7.label} ${fieldQ9.description}`} secondary={formValues.div7 ? "Yes" : "No"} /></ListItem>
                                            {formValues.div7 && <div style={{ paddingLeft: 16 }} className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.div7txt }}></div>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {edit}
                                    </Grid>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </List>
                </Grid>
            </Grid>}

            {
                (mode === FormMode.Edit) &&
                <>
                    <Grid item xs={12} sm={12}>
                        {title}
                        <div dangerouslySetInnerHTML={{ __html: diversityIntro2 }}></div>
                        {/* {subTitle}
                        <p>We would like to understand more about the leadership of the organisations that are applying to us.  This information will help us to inform our process and strategy and will support our decision-making process.</p>
                        <p>By leadership of the organisation, we mean the key decision-makers. We define an organisation as being ‘led by’ a particular category when the majority (75%* or more of the Board of Trustees/Management Committee AND 50% or more of senior staff, where staff exist) share a particular identity or experience.</p>
                        <p>* This figure of 75% - or 3 in 4 people - was decided on after working with a wide range of groups. We know this can only be an estimate.</p> */}
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 12 }}>
                        <Grid item xs={12} className="selectRadio" sm={12}>
                            <SelectMultipleToggleField
                                name={div4.name}
                                label={`${div4.label} ${fieldQ8a.description}`}
                                data={div4Choices}
                                value={div4.value}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                description={div4Desc}
                                extended
                            />
                        </Grid>
                        <Grid item xs={12} className="selectRadio" sm={12}>
                            <SelectMultipleToggleField
                                name={div5.name}
                                label={`${div5.label} ${fieldQ8b.description}`}
                                data={div5Choices}
                                value={div5.value}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                description={div5Desc}
                                extended />
                        </Grid>
                        <Grid item xs={12} className="selectRadio" sm={12}>
                            <SelectMultipleToggleField
                                name={div6.name}
                                label={`${div6.label} ${fieldQ8c.description}`}
                                data={div6Choices}
                                value={div6.value}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                description={div6Desc}
                                extended />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div dangerouslySetInnerHTML={{ __html:diversityIntro3}} />
                            <CheckboxField className={classes.toggleText} value={div7.value} name={div7.name} label={`${div7.label} ${fieldQ9.description}`} fullWidth variant="outlined" color="secondary" labelPlacement="start" description={div7Desc} />
                        </Grid>
                        {formValues.div7 && <Grid item xs={12} sm={12}>
                            <InputRichTextField
                                name={div7txt.name}
                                label={`${fieldQ9txt.description} *`}
                                fullWidth
                                color="secondary" variant="outlined"
                                description={div7txtDesc}
                            /></Grid>}
                    </Grid>
                </>
            }
        </div >
    )
}