import React, { useState } from 'react';
import {
    Button,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { SelectMultipleField, InputAdornmentField, CheckboxField } from '../../fields';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import { TEXT_NONE_OF_THE_ABOVE } from '../../common/DataTypes';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


import eligibilityCheckValidationSchema from './models/eligibilityCheckValidationSchema';
import eligibilityCheckFormModel from './models/eligibilityCheckFormModel';
import eligibilityCheckFormInitialValues from './models/eligibilityCheckFormInitialValues';
import styles from '../../header/styles';

const { formId, formField } = eligibilityCheckFormModel;

function EligibilityCheck04(props) {
    const classes = useStyles();
    const currentValidationSchema = eligibilityCheckValidationSchema[0];
    const [regCharity, setRegCharity] = React.useState(false);

    async function _submitForm(values, actions) {
        actions.setSubmitting(false);
        let isEligible = values.regCharity == true && values.eligibility03 == true && values.eligibility04 == true && values.eligibility05 == true && values.eligibility06 == true && values.eligibility07 == true && values.eligibility08 == true && values.eligibility09 == true && values.eligibility10 == true 
            && values.eligibility11 == true && values.eligibility12 == true && values.eligibility13 == true && values.eligibility14 == true && values.charitableWorkArea.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1;// && values.workPriorities.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1 ;
        let result = { passed: isEligible, checked: true, config: props.config, init: props.init, currentCycle: props.currentCycle, values: { regCharity: values.regCharity, eligibility03: values.eligibility03, eligibility05: values.eligibility05, eligibility06: values.eligibility06, eligibility07: values.eligibility07, eligibility08: values.eligibility08, eligibility09: values.eligibility09, eligibility10: values.eligibility10, lastFYTurnover: values.lastFYTurnover, charitableWorkArea: values.charitableWorkArea } };
        actions.setSubmitting(false);
        props.handleEligibility(result);
        actions.setSubmitting(false);
    }

    function _handleSubmit(values, actions) {
        _submitForm(values, actions);
    }

    function _handleStartOver(e) {
        window.location.href = window.location.href
    }


    const getChoices = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
        let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
        return choices;
    }

    const charitableWorkAreaChoices = props.init && getChoices("BGCharitableWorkArea") && getChoices("BGCharitableWorkArea");
    const introText = props.init && props.init.formTexts.IntroductionText;
    const eligCriteriaText = props.init && props.init.formTexts.EligiblityCriteriaText;
    return (
        <React.Fragment>
            <div className={classes.eligibilityCheck}>
                <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    {props.currentCycle.title}
                </Typography>
                <Formik
                    initialValues={eligibilityCheckFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form id={formId}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
                                        Eligibility Criteria
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: eligCriteriaText }}></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary">
                                        Eligibility Check
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.regCharity.name} label={formField.regCharity.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility04.name} label={formField.eligibility04.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility03.name} label={formField.eligibility03.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility14.name} label={formField.eligibility14.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                               
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility05.name} label={formField.eligibility05.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility06.name} label={formField.eligibility06.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility07.name} label={formField.eligibility07.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility08.name} label={formField.eligibility08.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility09.name} label={formField.eligibility09.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility10.name} label={formField.eligibility10.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility13.name} label={formField.eligibility13.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility11.name} label={formField.eligibility11.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField className={classes.toggleText} name={formField.eligibility12.name} label={formField.eligibility12.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectMultipleField
                                        name={formField.charitableWorkArea.name}
                                        label={formField.charitableWorkArea.label}
                                        data={charitableWorkAreaChoices.filter((item) => item.indexOf('West Midlands') == -1 && item.indexOf('Wimbledon') == -1 && item.indexOf('City of London') == -1 && item.indexOf('Somerset') != 0)}
                                        value={formField.charitableWorkArea.value}
                                        fullWidth
                                        variant="outlined"
                                        color="secondary"
                                    />
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <div className={classes.wrapper}>
                                        <div className={classes.buttons}>
                                            <Button
                                                onClick={_handleStartOver}
                                                disabled={isSubmitting}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonNext}
                                                startIcon={<ArrowLeftIcon />}
                                            >Start Over</Button>
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonNext}
                                                endIcon={<ArrowRightIcon />}
                                            >Next</Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
}

export default EligibilityCheck04
