import React from 'react';
import { Grid, Typography, Link, Button, IconButton, FormHelperText } from '@material-ui/core';
import { InputField, InputRichTextField, InputAdornmentField, SelectMultipleField, CheckboxField } from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { SelectType, TEXT_NONE_OF_THE_ABOVE } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { FormMode } from '../models/FormMode';

export default function AccessibilityDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    editable,
    mode,
    getField,
    formField: {
      language,
      format,
      consider,
      adjustments,
      adjustmentsTxt
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

  const fieldLanguage = props.init && getField("FRQuestion01");
  const fieldFormat = props.init && getField("FRQuestion02");
  const fieldConsider = props.init && getField("FRQuestion03");
  const fieldAdjustments = props.init && getField("FRQuestion04");

  const accessibilityStage1Intro = props.init && props.init.formTexts.AccessibilityStage1Intro;
  const languageDesc = props.init && props.init.formTexts.AccessibilityLanguage;
  const formatDesc = props.init && props.init.formTexts.AccessibilityFormat;
  const considerDesc = props.init && props.init.formTexts.AccessibilityConsider;
  const adjustmentsDesc = props.init && props.init.formTexts.AccessibilityAdjustments;
  const adjustmentsTxtDesc = props.init && props.init.formTexts.AccessibilityAdjustmentsTxt;

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }

  const modules = {
    toolbar: null
  };
  const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  const hintText = `<p style='text-align:center;font-style:italic;color:#0000008a'>* We are happy to discuss your requirements and make reasonable adjustments where needed. Please email us at <a style='color:#0000008a' href="mailto:info@berkeleyfoundation.org.uk">info@berkeleyfoundation.org.uk</a> or call us on 01932 868555.</p>`
  return (
    <div className={classes.accessibilitydetails}>
      {(mode === FormMode.Review) &&
        <List style={{ width: '100%' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accessibilitydetails-content"
              id="accessibilitydetails-header"
            >
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${language.label} ${fieldLanguage.description}`} secondary={editable ? (formValues.language ? "Yes" : "No") : formValues.language} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${format.label} ${fieldFormat.description}`} secondary={editable ? (formValues.format ? "Yes" : "No") : formValues.format} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${consider.label} ${fieldConsider.description}`} secondary={editable ? (formValues.consider ? "Yes" : "No") : formValues.consider} /></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText className={classesFields.listItem} primary={`${adjustments.label} ${fieldAdjustments.description}`} secondary={formValues.adjustments ? <><span>Yes</span><div className={classes.reviewRichtext} dangerouslySetInnerHTML={{ __html: formValues.adjustmentsTxt }}></div></> : <span>No</span>} /></ListItem>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {edit}
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </List>
      }

      {(mode == FormMode.Stage) &&
        <> {title}
          <List>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${language.label} ${fieldLanguage.description}`} secondary={formValues.language} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${format.label} ${fieldFormat.description}`} secondary={formValues.format} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${consider.label} ${fieldConsider.description}`} secondary={formValues.consider} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${adjustments.label} ${fieldAdjustments.description}`} secondary={formValues.adjustments == 'Yes' ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.adjustmentsTxt} modules={modules} /></div> : "No"} /></ListItem></div>
              </Grid>
            </Grid>
          </List></>
      }
      {(mode === FormMode.Edit) && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.info} variant="outlined" color="primary">
              <CardContent>
                <div dangerouslySetInnerHTML={{ __html: accessibilityStage1Intro }}></div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <CheckboxField className={classes.toggleText} name={language.name} label={`${language.label} ${fieldLanguage.description}`} showAstkNo fullWidth variant="outlined" color="secondary" labelPlacement="start" description={languageDesc} />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField className={classes.toggleText} name={format.name} label={`${format.label} ${fieldFormat.description}`} showAstkYes fullWidth variant="outlined" color="secondary" labelPlacement="start" description={formatDesc} />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField className={classes.toggleText} name={consider.name} label={`${consider.label} ${fieldConsider.description}`} showAstkYes fullWidth variant="outlined" color="secondary" labelPlacement="start" description={considerDesc} />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField className={classes.toggleText} name={adjustments.name} label={`${adjustments.label} ${fieldAdjustments.description}`} showAstkYes fullWidth variant="outlined" color="secondary" labelPlacement="start" description={adjustmentsDesc} />
          </Grid>

          {formValues.adjustments && <Grid item xs={12} sm={12}>
            <InputRichTextField
              name={adjustmentsTxt.name}
              label={`If yes, please describe`}
              fullWidth
              color="secondary" variant="outlined"
              description={adjustmentsTxtDesc}
              required={formValues.adjustments.value}
            />
          </Grid>}
          {(!formValues.language || formValues.format || formValues.consider || formValues.adjustments) && <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: hintText }}></div>
          </Grid>}
        </Grid>
      </>}
    </div>
  )
}