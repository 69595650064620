export const TEXT_NONE_OF_THE_ABOVE = 'None of the above';
export const TEXT_PREFER_NOT_TO_SAY = 'Prefer not to say';
export const TEXT_TOTAL_EXCEED = 'Total must be 100%';
export const MSG_REQUIRED = 'This field is required';
export const MSG_MIN_REQUIRED = 'At least one must be selected';
export const MSG_FORMAT_ERROR = 'Must be separated by semi-colon';

export const SelectType = {
   RADIO: 1,
   CHECK: 2,
   NONE: 3
}

export const DEIFields = {
   Question1: "EDIQuestion01",
   Question2: "EDIQuestion02",
   Question2a: "EDIQuestion02a",
   Question2b: "EDIQuestion02b",
   Question2c: "EDIQuestion02c",
   Question2d: "EDIQuestion02d",
   Question2e: "EDIQuestion02e",
   Question2f: "EDIQuestion02f",
   Question2g: "EDIQuestion02g",
   Question2h: "EDIQuestion02h",
   Question2i: "EDIQuestion02i",
   Question3: "EDIQuestion03",

   //Question4: "EDIQuestion04",
   //Question5: "BGLocation",
   //Question6: "EDIQuestion06",
   Question6a: "EDIQuestion06a",
   Question6b: "EDIQuestion06b",
   Question6c: "EDIQuestion06c",
   Question6d: "EDIQuestion06d",
   Question6e: "EDIQuestion06e",
   Question6f: "EDIQuestion06f",
   Question6g: "EDIQuestion06g",
   Question6h: "EDIQuestion06h",
   Question6i: "EDIQuestion06i",
   Question7: "EDIQuestion07",

   Question4: 'EDIQuestion8a',
   Question5: 'EDIQuestion8b',
   Question6: 'EDIQuestion8c',
   //Question8d: 'EDIQuestion8d',
   Question9txt: 'EDIQuestion09txt',
   Question9: 'EDIQuestion09',

}