import * as Yup from 'yup';
import formModel from './formModel2';
const {
  formField: {
    q17,
    q18,
    q19,
    q20,
    q21,
    q22,
    q23,
    q24,
    q25,
    q26,
    q27,
    attachments
  }
} = formModel;


export default [
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({
    [q21.name]: Yup.string()
      .required(`${q21.requiredErrorMsg}`)
      .test('len', `${q21.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      )
  }),
  Yup.object().shape({
    [q22.name]: Yup.string()
      .required(`${q22.requiredErrorMsg}`)
      .test('len', `${q22.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q23.name]: Yup.array().min(1, `${q23.requiredErrorMsg}`),
    [q24.name]: Yup.string()
      .required(`${q24.requiredErrorMsg}`)
      .test('len', `${q24.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q25.name]: Yup.string()
      .required(`${q25.requiredErrorMsg}`)
      .test('len', `${q25.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q26.name]: Yup.number()
      .required(`${q26.requiredErrorMsg}`)
      .positive(() => `Invalid number`),
    [q27.name]: Yup.number()
      .required(`${q27.requiredErrorMsg}`)
      .positive(() => `Invalid number`)
      .max(90000, `This field must not exceed £90000.`)
  }),
  Yup.object().shape({
    //[attachments.name]: Yup.array().min(1, `${attachments.requiredErrorMsg}`),
  })
];
