import React, { useEffect } from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, InputAdornmentField, SelectMultipleField } from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';
import { FormMode } from '../models/FormMode';

export default function ProjectDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    formField: { q20, q22, q23, q24, q25, q26, q27 }, getChoices, getField, mode
  } = props;
  const { values: formValues } = useFormikContext();

  const q23Choices = props.init && getChoices("FR04Question23") && getChoices("FR04Question23");
  const fieldQ20 = props.init && getField("FR04Question20");
  const fieldQ22 = props.init && getField("FR04Question22");
  const fieldQ23 = props.init && getField("FR04Question23");
  const fieldQ24 = props.init && getField("FR04Question24");
  const fieldQ25 = props.init && getField("FR04Question25");
  const fieldQ26 = props.init && getField("FR04Question26");
  const fieldQ27 = props.init && getField("BGRequestedFunding");

  const question20Desc = props.init && props.init.formTexts.Question20;
  const question22Desc = props.init && props.init.formTexts.Question22;
  const question23Desc = props.init && props.init.formTexts.Question23;
  const question24Desc = props.init && props.init.formTexts.Question24;
  const question25Desc = props.init && props.init.formTexts.Question25;
  const question26Desc = props.init && props.init.formTexts.Question26;
  const question27Desc = props.init && props.init.formTexts.RequestedFunding;

  const modules = {
    toolbar: null
  };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{mode == FormMode.Edit ? props.steps[props.stepId] : `Stage 2: ${props.steps[props.stepId]}`}</Typography>;
  const aboutProject = props.init && props.init.formTexts.AboutProject;

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
    <div className={classes.aboutProject}>
      {mode == "Review" &&
        <Grid container>
          <Grid item xs={12}>
            <List><Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {title}
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q20.label} ${fieldQ20.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q20} modules={modules} /></ListItem></div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q22.label} ${fieldQ22.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q22} modules={modules} /></ListItem></div>
                </Grid>
                <Grid item xs={12}>
                  <ListItem button><ListItemText className={classesFields.listItem} primary={`${q23.label} ${fieldQ23.description}`} secondary={formValues.q23 && formValues.q23.length > 0 ? formValues.q23.join(', ') : ''} /></ListItem>
                </Grid>
                <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q24.label} ${fieldQ24.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q24} modules={modules} /></ListItem></div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q25.label} ${fieldQ25.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q25} modules={modules} /></ListItem></div>
                </Grid>
                <Grid item xs={12}>
                  <ListItem button><ListItemText className={classesFields.listItem} primary={`${q26.label} ${fieldQ26.description}`} secondary={'£' + formValues.q26.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
                </Grid>
                <Grid item xs={12}>
                  <ListItem button><ListItemText className={classesFields.listItem} primary={`${q27.label} ${fieldQ27.description}`} secondary={'£' + formValues.q27.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {edit}
                </Grid>
              </AccordionDetails>
            </Accordion></List>
          </Grid>
        </Grid>
      }
      {mode == "Edit" && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: aboutProject }}></div>
          </Grid>
          <Grid item xs={12}>
           <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q20.label} ${fieldQ20.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q20} modules={modules} /></ListItem></div>
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q22.name}
              label={`${q22.label} ${fieldQ22.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question22Desc}
            />
          </Grid>
          <Grid item xs={12}>

            <SelectMultipleField
              name={q23.name}
              label={`${q23.label} ${fieldQ23.description} *`}
              data={q23Choices}
              value={q23.value}
              fullWidth
              variant="outlined"
              color="secondary"
              description={question23Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q24.name}
              label={`${q24.label} ${fieldQ24.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question24Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q25.name}
              label={`${q25.label} ${fieldQ25.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question25Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputAdornmentField
              name={q26.name}
              label={`${q26.label} ${fieldQ26.description} *`}
              adornment="£"
              fullWidth
              type="number"
              color="secondary" variant="outlined"
              description={question26Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputAdornmentField
              name={q27.name}
              label={`${q27.label} ${fieldQ27.description} *`}
              adornment="£"
              fullWidth
              type="number"
              color="secondary" variant="outlined"
              description={question27Desc}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
          </Grid>
        </Grid>
      </>}
    </div>
  );
}
