import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { InputField } from '../../fields';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import securityCheckValidationSchema from './models/securityCheckValidationSchema';
import securityCheckFormModel from './models/securityCheckFormModel';
import securityCheckFormInitialValues from './models/securityCheckFormInitialValues';
import formInitialValues from './models/formInitialValues';


import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio
} from '@material-ui/core';

const { formId, formField } = securityCheckFormModel;

function SecurityCheck04(props) {
    const classes = useStyles();
    const currentValidationSchema = securityCheckValidationSchema[0];
    const [errorCode, setErrorCode] = React.useState('');
    const currentCode = window.location.search.replace('?', '');

    const [withCode, setWithCode] = React.useState(currentCode ? true : false);
    const [newApplication, setNewApplication] = React.useState(currentCode ? false : true);
    securityCheckFormInitialValues.code = currentCode || '';

    /* useEffect(() => {
        getStage(currentCode).then(stage => {
            if (stage) {
                const item = stage.Item;
                formInitialValues.salutation = item.BGSalutation;
                formInitialValues.firstName = item.FirstName;
                formInitialValues.lastName = item.BGLastName;
                formInitialValues.jobTitle = item.JobTitle;
                formInitialValues.email = item.BGEmail;
                formInitialValues.phone = item.BGPhoneNumber;
                formInitialValues.address = item.BGAddress;
                formInitialValues.postcode = item.BGPostalCode;
                formInitialValues.orgName = item.BGOrganisationName;
                formInitialValues.orgType = item.BGOrganisationType;
                formInitialValues.website = item.BGWebsite;
                formInitialValues.regCharityNumber = item.BGRegisteredCharityNumber;
                formInitialValues.regCompanyNumber = item.BGRegisteredCompanyNumber;
                formInitialValues.lastFYTurnover = item.BGLastFYTurnover;
                formInitialValues.charitableWorkArea = item.BGCharitableWorkArea;

                formInitialValues.q1 = item.FR03Question01 || '';
                 formInitialValues.q2 = item.FR03Question02 || '';
                formInitialValues.q3 = item.FR03Question03 || [];
                formInitialValues.q4 = item.FR03Question04 || '';
                formInitialValues.q5 = item.FR03Question05 || '';
                formInitialValues.q6 = item.FR03Question06 || '';
                formInitialValues.q7 = item.BGRequestedFunding || '';
                formInitialValues.q8 = item.FR03Question08 || '';
                formInitialValues.q9 = item.FR03Question09 || '';
                formInitialValues.q10 = item.FR03Question10 || '';
                formInitialValues.q11 = item.FR03Question11 || ''; 
                let stageResult = { passed: true, checked: true, stage: stage, ...props, formInitialValues: formInitialValues };
                props.handleSecurityCheck(stageResult);
            } else {
                setErrorCode(formField.code.invalidErrorMsg);
            }
        });
      }, [currentCode]) */

    async function _submitForm(values, actions) {
        console.log('_submitForm');
        if (withCode && values.code) {
            actions.setSubmitting(true);
            getStage(values.code).then(stage => {
                if (stage) {
                    const item = stage.Item;
                    //accesibility
                    formInitialValues.language = item.FRQuestion01;
                    formInitialValues.format = item.FRQuestion02;
                    formInitialValues.consider = item.FRQuestion03;
                    formInitialValues.adjustments = item.FRQuestion04;
                    formInitialValues.adjustmentsTxt = item.FRQuestion04txt;
                    //diversity
                    formInitialValues.div1 = item.EDIQuestion01 || '';
                    formInitialValues.div2 = item.EDIQuestion02 || [];
                    formInitialValues.div2a = item.EDIQuestion02a || [];
                    formInitialValues.div2b = item.EDIQuestion02b || [];
                    formInitialValues.div2c = item.EDIQuestion02c || [];
                    formInitialValues.div2d = item.EDIQuestion02d || [];
                    formInitialValues.div2e = item.EDIQuestion02e || [];
                    formInitialValues.div2f = item.EDIQuestion02f || [];
                    formInitialValues.div2g = item.EDIQuestion02g || [];
                    formInitialValues.div2h = item.EDIQuestion02h || [];
                    formInitialValues.div2i = item.EDIQuestion02i || [];

                    formInitialValues.div3 = item.EDIQuestion03 || '';
                    formInitialValues.div4 = item.EDIQuestion8a || [];
                    formInitialValues.div5 = item.EDIQuestion8b || [];
                    formInitialValues.div6 = item.EDIQuestion8c || [];
                    formInitialValues.div7 = item.EDIQuestion09 || '';
                    formInitialValues.div7txt = item.EDIQuestion09txt || '';
                    //Stage 1
                    formInitialValues.salutation = item.BGSalutation;
                    formInitialValues.firstName = item.FirstName;
                    formInitialValues.lastName = item.BGLastName;
                    formInitialValues.jobTitle = item.JobTitle;
                    formInitialValues.email = item.BGEmail;
                    formInitialValues.phone = item.BGPhoneNumber;
                    formInitialValues.address = item.BGAddress;
                    formInitialValues.postcode = item.BGPostalCode;
                    formInitialValues.orgName = item.BGOrganisationName;
                    formInitialValues.orgType = item.BGOrganisationType;
                    formInitialValues.website = item.BGWebsite;
                    formInitialValues.regCharityNumber = item.BGRegisteredCharityNumber;
                    formInitialValues.regCompanyNumber = item.BGRegisteredCompanyNumber;
                    formInitialValues.lastFYTurnover = item.BGLastFYTurnover;
                    formInitialValues.charitableWorkArea = item.BGCharitableWorkArea;
                    formInitialValues.q16 = item.FR04Question16 || '';

                    formInitialValues.q17 = item.FR04Question17 || '';
                    formInitialValues.q18 = item.FR04Question18 || '';
                    formInitialValues.q19 = item.FR04Question19 || '';
                    formInitialValues.q20 = item.FR04Question20 || '';

                    formInitialValues.q21 = item.FR04Question21 || '';
                    formInitialValues.q22 = item.FR04Question22 || '';
                    formInitialValues.q23 = item.FR04Question23 || [];
                    formInitialValues.q24 = item.FR04Question24 || '';
                    formInitialValues.q25 = item.FR04Question25 || '';
                    formInitialValues.q26 = item.FR04Question26 || '';
                    formInitialValues.q27 = item.BGRequestedFunding || '';
                    let stageResult = { passed: true, checked: true, stage: stage, ...props, formInitialValues: formInitialValues, docsExists: stage.docs && stage.docs.length > 0 || false };
                    actions.setSubmitting(false);
                    props.handleSecurityCheck(stageResult);
                    actions.setSubmitting(false);
                } else {
                    setErrorCode(formField.code.invalidErrorMsg);
                    actions.setSubmitting(false);
                }
            });
        } else if (newApplication) {
            console.log('_submitForm > newApplication');
            actions.setSubmitting(true);
            let stageResult = { passed: true, checked: true, stage: null, ...props };
            props.handleSecurityCheck(stageResult);
            actions.setSubmitting(false);
        } else {
            actions.setSubmitting(false);
            setErrorCode(formField.code.requiredErrorMsg)
            return false;
        }
    }

    function _handleSubmit(values, actions) {
        _submitForm(values, actions);
    }


    const handleSelection = (event) => {
        setErrorCode('');
        if (event.target.value == 'new') {
            setNewApplication(true);
            setWithCode(false);
        } else {
            setWithCode(true);
            setNewApplication(false);
        }
    }

    const getStage = (code) => {
        console.log("Fetching:", props.config.ApiUrl);

        return new Promise((resolve, reject) => {
            const postData = {
                siteId: props.init.siteId,
                listId: props.init.listId,
                accessCode: code,
                docLibId: props.init.docLibId
            };

            let apiUrl = `${props.config.ApiUrl}/stage`;

            return fetch(apiUrl,
                {
                    body: JSON.stringify(postData),
                    method: "POST",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "Authorization": `Bearer ${props.config.ApiToken}`
                    }
                })
                .then(response => response.json())
                .then(json => {
                    resolve(json.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    const introText = props.init && props.init.formTexts.IntroductionText;
    const securityCheckIntro = props.init && props.init.formTexts.SecurityCheckIntro;
    return (
        <React.Fragment>
            <div className={classes.eligibilityCheck}>
                <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    {props.currentCycle.title}
                </Typography>
                <Formik
                    initialValues={securityCheckFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form id={formId}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: securityCheckIntro }}></div>
                                </Grid>


                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel>{formField.startMode.label}</FormLabel>
                                        <RadioGroup
                                            defaultValue={currentCode ? 'code' : 'new'}
                                            name={formField.startMode.name}
                                            onChange={handleSelection}
                                        >
                                            <FormControlLabel value="new" control={<Radio name={formField.newApplication.name} />} label={formField.newApplication.label} />
                                            <FormControlLabel value="code" control={<Radio name={formField.withCode.name} />} label={formField.withCode.label} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {withCode && <Grid item xs={12} style={{ paddingBottom: 12 }}>
                                    <InputField name={formField.code.name} label={`${formField.code.label}`} fullWidth variant="outlined" color="secondary" />
                                    {errorCode && <span style={{ color: "#ce0d28" }}>{errorCode}</span>}
                                </Grid>}
                                <Grid item xs={12}>
                                    <div className={classes.wrapper}>
                                        <div className={classes.buttons}>
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonNext}
                                                endIcon={<ArrowRightIcon />}
                                            >{withCode ? 'Continue' : 'Start'}</Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );


}

export default SecurityCheck04
