import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Link,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { EligibilityContext } from '../../common/EligibilityContext';
import axios from "axios";
import useStyles from '../styles';
import SendIcon from '@material-ui/icons/Send';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import validationSchema from './models/validationSchema';
import formModel from './models/formModel';
import formInitialValues from './models/formInitialValues';
import { Success, PersonalDetails, Review, DiversityDetails1,DiversityDetails2,DiversityDetails3, AccessibilityDetails } from './sections';
import Errors from '../Errors';

import ReCAPTCHA from "react-google-recaptcha";

const STEPS = {
  Accessibility: 0,
  Stage1: 1,
  EquityDiversityandInclusion1: 2,
  EquityDiversityandInclusion2: 3,
  // SupportDocs: 4,
  ReviewSubmit: 4,
  Success: 5
}
const steps = ['Accessibility', 'Stage 1 Application', 'Diversity, Equity and Inclusion', 'Diversity, Equity and Inclusion', 'Review and Submit'];
const { formId, formField } = formModel;
const SKIP_STEP = 3;
function renderStepContent(step, props) {
  switch (step) {
    case STEPS.Accessibility:
      return <AccessibilityDetails formField={formField} {...props} mode="Edit" />;
    case STEPS.Stage1:
      return <PersonalDetails formField={formField} {...props} mode="Edit" />;
    case STEPS.EquityDiversityandInclusion1:
      return <DiversityDetails1 formField={formField} {...props} mode="Edit" />;
    case STEPS.EquityDiversityandInclusion2:
      return <DiversityDetails2 formField={formField} {...props} mode="Edit" />;
    case STEPS.ReviewSubmit:
      return <Review formField={formField} {...props} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function MainForm041(props) {
  const { eligibilityResult } = useContext(EligibilityContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const allValidationSchema = validationSchema;
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const recaptchaRef = useRef();
  const config = eligibilityResult.config;

  const [serverErrors, setServerErrors] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [skipped, setSkipped] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    formInitialValues.charitableWorkArea = eligibilityResult.values.charitableWorkArea;
    //formInitialValues.lastFYTurnover = eligibilityResult.values.lastFYTurnover;
  }, [eligibilityResult]);

  const getChoices = (name) => {
    let { fields } = eligibilityResult.init;
    let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
    let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
    return choices;
  }

  const getField = (name) => {
    let { fields } = eligibilityResult.init;
    let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
    return field;
  }

  async function submitForm(values, actions, attachments) {
    let token = '';
    if (eligibilityResult.init.humanEnabled) {
      if (recaptchaRef.current) {
        token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
      }
    }
    setServerErrors([]);
    let currentCycle = eligibilityResult.currentCycle;

    const postData = {
      ...values,
      cycle: currentCycle,
      initData: eligibilityResult.init,
      token,
      isRegCharity: eligibilityResult.values.regCharity,
      safeguardingPolicy: eligibilityResult.values.eligibility10,
      attachments: attachments,
      humanEnabled: eligibilityResult.init.humanEnabled
    };
    let apiUrl = `${config.ApiUrl}/new${currentCycle.id}`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
    const response = await fetch(apiUrl,
      {
        body: JSON.stringify(postData),
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${config.ApiToken}`
        }
      })
      .catch(error => {
        setServerErrors([{ message: error }]);
        actions.setSubmitting(false);
      });
    const result = await response.json();
    console.log('result', result);
    if (result) {
      if (result.data) {
        uploadFiles(actions, attachments, result.data.BGApplication);
      }
    }
    else if (result.errors) {
      setServerErrors(result.errors);
      actions.setSubmitting(false);
    } else if (result.error) {
      setServerErrors([{ message: result.error }]);
      actions.setSubmitting(false);
    }
  }

  function uploadFiles(actions, attachments, appRef) {
    if (skipped) {
      setActiveStep(6);
      setServerErrors([]);
      actions.setSubmitting(false);
      return;
    }
    attachments.map(attachment => {
      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("fileName", attachment.name);
      formData.append("init", eligibilityResult.init);
      formData.append("siteId", eligibilityResult.init.siteId);
      formData.append("listId", eligibilityResult.init.listId);
      formData.append("docLibId", eligibilityResult.init.docLibId);
      formData.append("appRef", appRef);
      formData.append("cycle", eligibilityResult.currentCycle.cycle);

      let apiUrl = config.ApiUrl;
      try {
        const response = axios.post(
          `${apiUrl}/upload`,
          formData
        );
        const result = response.json();

        if (result) {
          //dont wait, update progress?  
        }
      } catch (ex) {
        console.log(ex);
        // setServerErrors([{message:ex}]);
        //actions.setSubmitting(false);
      }
    });
    setActiveStep(STEPS.Success);
    setServerErrors([]);
    actions.setSubmitting(false);
  };

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions, attachments);
    } else {
      let nextStep = activeStep + 1;
      setActiveStep(nextStep);
      actions.setTouched({});
      actions.setSubmitting(false);
      /* if (activeStep == 2) {
        setDisableNext(attachments.length == 0);
      } */
    }
  }

  function handleBack() {
    let prevStep = activeStep - 1;
    setActiveStep(prevStep);
    if (prevStep == STEPS.ReviewSubmit) {
      //setDisableNext(attachments.length == 0);
    } else {
      setDisableNext(false);
    }
  }

  const onEdit = (step) => {
    setActiveStep(step);
  }

  const isStepOptional = (step) => {
    return step === SKIP_STEP;
  };

  const handleAttachments = (array) => {
    array.forEach((item, index) => {
      attachments.push(item);
    });
    setAttachments(attachments);
    setSkipped(false);
    setDisableNext(attachments.length == 0);
  }


  const onFilesRemoved = (array) => {
    setAttachments(array);
    setSkipped(false);
    setDisableNext(array.length == 0);
  }

  const formProps = { steps, stepId: activeStep, init: eligibilityResult.init, onEdit, getChoices, getField, handleAttachments, attachments, onFilesRemoved, skipped };

  return (
    <div className={classes.eligibilityCheck}>
      <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
        {eligibilityResult.currentCycle.title}
      </Typography>
      {activeStep === STEPS.Success ? (
        <Success {...formProps} textKey="Success" />
      ) : (
        <React.Fragment>
          {activeStep < STEPS.ReviewSubmit && <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel optional={label === 'Diversity, Equity and Inclusion' ? `(Part ${steps.length - 6 + index} of 2)` : ''}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>}
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
            style={{ width: '100%' }} >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {renderStepContent(activeStep, formProps)}

                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    {activeStep !== STEPS.Accessibility && (
                      <Button onClick={handleBack} startIcon={<ArrowLeftIcon />} className={classes.buttonBack} variant="contained" color="default">
                        Back
                      </Button>
                    )}

                    <Button
                      disabled={disableNext}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.buttonNext}
                      endIcon={isLastStep ? <SendIcon /> : <ArrowRightIcon />}
                    >
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
                <div className="reCaptcha">
                  {activeStep === STEPS.ReviewSubmit && eligibilityResult.init.humanEnabled && <ReCAPTCHA
                    sitekey={config.RecaptchaKey}
                    ref={recaptchaRef}
                    size="invisible"
                  />
                  }
                </div>
              </Form>
            )}
          </Formik>
          {serverErrors && serverErrors.length > 0 && <Errors errors={serverErrors} />}
        </React.Fragment>
      )}
    </div>
  );
}
