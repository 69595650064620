import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField,FormHelperText } from '@material-ui/core';
import useStyles from './styles';
import SimplePopover from './SimplePopover';

export default function InputField(props) {
  const {description, errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const classes = useStyles();

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    const helperText = <div>{error}</div>;
    const popover = <SimplePopover {...props} content={description}/>
    if((touched && error) || description) {
      return <div> {(touched && error)? helperText:''} {description ? popover:''} </div>
    }
  }

  return (
    <TextField
      className={ props.extended ? classes.singleTextExtended: classes.singleText}
      type="text"
      placeholder={props.description2 || 'Enter text here'}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      autoComplete="off"
      description={props.description2 || ''}
    />
  );
}
