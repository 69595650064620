import formModel from './formModel';
const {
  formField: {
    language,
    format,
    consider,
    adjustments,
    adjustmentsTxt,
    salutation,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    //organisationIDNumber,
    regCharityNumber,
    regCompanyNumber,
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    q17,
    q18,
    q19,
    q20,
    q21,
    q22,
    q23,
    q24,
    q25,
    q26,
    div1,
    div2,
    div3,
    div4,
    div5,
    div6,
    div7txt,
  }
} = formModel;

const DEVTEXT = 'development';

export default {
  [language.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [format.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [consider.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [adjustments.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [adjustmentsTxt.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem Ipsum' : '',
  [salutation.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Mr' : '',
  [firstName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'B' : '',
  [lastName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'A' : '',
  [jobTitle.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'D' : '',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'puru.ramakrishna@berkeleygroup.co.uk' : '',
  [confirmEmail.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'puru.ramakrishna@berkeleygroup.co.uk' : '',
  [phone.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '07809999999' : '',
  [orgName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'O' : '',
  [address.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'T' : '',
  [postcode.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'SW1 5KL' : '',
  [website.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'https://www.bg.co.uk' : '',
  [regCharityNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '123433' : '',
  [regCompanyNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '23456' : '',
  [orgType.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Charity' : '',
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '34567' : '',
  [charitableWorkArea.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Kent', 'Hampshire'] : [],

  [q17.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 17' : '',
  [q18.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 18' : '',
  [q19.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 19' : '',
  [q20.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 20' : '',
  [q21.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 21' : '',
  [q22.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 22' : '',
  [q23.name]: process.env.REACT_APP_API_URL &&  process.env.NODE_ENV === DEVTEXT ? [''] : [],
  [q24.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 24' : '',
  [q25.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum 25' : '',

  [div1.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'A;B;C' : '',
  [div2.name]: process.env.REACT_APP_API_URL &&  process.env.NODE_ENV === DEVTEXT ? [] : [],
  [div3.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'A;B;C' : '',
  
  [div4.name]: process.env.REACT_APP_API_URL &&  process.env.NODE_ENV === DEVTEXT ? [] : [],
  [div5.name]: process.env.REACT_APP_API_URL &&  process.env.NODE_ENV === DEVTEXT ? [] : [],
  [div6.name]: process.env.REACT_APP_API_URL &&  process.env.NODE_ENV === DEVTEXT ? [] : [],
  [div7txt.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
}; 