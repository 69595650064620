import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  info: {
    backgroundColor:'#8aaf45',
    color:'#fff',
    lineHeight:1.66,
    borderColor:'#8b9d3e',
    borderRadius:0,

    '& .MuiCardContent-root': {
      paddingBottom:0
    },
    success: {
      '& a': {
        color:'#fff'
      }
    },
  },

  infoSave: {
    backgroundColor:'#F7F7F8',
    color:'#063006',
    lineHeight:1.66,
    borderColor:'#EAEAEA',
    borderRadius:0,

    '& .MuiCardContent-root': {
      paddingBottom:0 
    },

    '& a': {
      color:'#909090 !important'
    }
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  title: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  subTitle: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,

    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  buttonNext: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color:'#fff',
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    color:'#fff',
    letterSpacing:2
  },
  buttonStartNow: {
    marginLeft: theme.spacing(1),
    color:'#fff',
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    color:'#fff',
    letterSpacing:2
  },
  buttonSaveforLater: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    color:'#fff',
    letterSpacing:2
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    letterSpacing:2,
    color:'#666',
  },
  buttonEdit: {
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    letterSpacing:2,
    color:'#666',
    float:'right'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  notice: {
    backgroundColor:theme.palette.primary.main,
    color:'#fff'
  },
  regcharityText: {
    paddingLeft:10,
    '& b' : {
      color:theme.palette.secondary.main
    }
  },
  labelMultiline: {
    fontSize: '1.4em',
    lineHeight:'1.6em',
    width: 'auto'
  },

  review: {
    width: '100%',
    '& a': {
      color:theme.palette.secondary.main,
      textDecoration:'none',
      '&:hover': {
        textDecoration:'underline',
      }
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subTitle: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    paddingBottom:10,
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  mail: {
    color:theme.palette.secondary.main,
    textDecoration:'none',
    '&:hover': {
      textDecoration:'underline',
    }
  },
  ineligibile: {
    '& a': {
      color:'#fff'
    }
  },
  
  error: {
    color:'#f44336',
    fontSize: '0.75rem'
  },

  webinar: {
    '& a': {
      color:theme.palette.secondary.main,
    }
  },

  webinarIntro: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },

  stopperLeft: {
    /* background: 'url(https://cdn.hubblecontent.osi.office.net/videos/publish/67269665-1d43-439a-9ed2-758a25c640b5/thumbnails/large.jpg) no-repeat',
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    'background-size': 'cover', */
    width: '-webkit-fill-available',
    [theme.breakpoints.down('xs')]: { 
      display:'none'
    },
    '& img': {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available'
    }
  },

  stopperRight: { },

  questionsform: {
    marginTop:24,
    
  },

  question5Webinar: {
    marginTop:'3.4em'
  },

  accessibilitydetails: {
    width:'100%',
    '& a': {
      color:'#fff',
    }
  },

  diversitydetails: {
    '& a': {
      color:theme.palette.secondary.main,
    }
  },

 
  
  personaldetails: {
    '& a': {
      color:theme.palette.secondary.main,
    }
  },
  aboutProject: {
    width:'100%',
       '& a': {
      color:theme.palette.secondary.main,
    }
  },
  aboutOrg: {
    width:'100%',
    '& a': {
      color:theme.palette.secondary.main,
    }
  },
  documents: {
    width:'100%',
    
    '& a': {
      color:theme.palette.secondary.main,
      
    }
  },
  
  safeguardingPolicy: {
    marginRight:0,
    '& b' : {
      color:theme.palette.secondary.main
    }
  },

  success: {
    '& a': {
      color:'#fff'
    }
  },

}));
