import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    eligibility03,
    eligibility04,
    charitableWorkArea,
    eligibility05,
    eligibility06,
    eligibility07,
    eligibility08,
    eligibility09,
    eligibility10,
    eligibility11,
    eligibility12,
    eligibility13,
    eligibility14,
  }
} = eligibilityCheckFormModel;

const DEVTEXT = 'development';

 export default {
  [regCharity.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility03.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility04.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility05.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility06.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility07.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility08.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility09.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility10.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility11.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility12.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility13.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility14.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT? ['Kent', 'Hampshire']:[],
}; 
