import React, { useEffect } from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, CheckboxField } from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';
import { FormMode } from '../models/FormMode';

export default function OrganisationDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: { q17, q18, q19, q21, q22, q23, q24, q25 }, getField, mode } = props;
  const { values: formValues } = useFormikContext();
  const fieldQ17 = props.init && getField("FR04Question17");
  const fieldQ18 = props.init && getField("FR04Question18");
  const fieldQ19 = props.init && getField("FR04Question19");
  const fieldQ21 = props.init && getField("FR04Question21");

  const fieldQ7 = props.init && getField("FR03Question07");
  const fieldQ9 = props.init && getField("FR03Question09");
  const fieldQ10 = props.init && getField("FR03Question10");
  const fieldQ11 = props.init && getField("FR03Question11");
  const fieldQ12 = props.init && getField("FR03Question12");

  const question14Desc = props.init && props.init.formTexts.Question14;
  const question17Desc = props.init && props.init.formTexts.Question17;
  const question18Desc = props.init && props.init.formTexts.Question18;
  const question19Desc = props.init && props.init.formTexts.Question19;
  const ququestion21Desc = props.init && props.init.formTexts.Question21;
  const question22Desc = props.init && props.init.formTexts.Question7;
  const question23Desc = props.init && props.init.formTexts.Question10;
  const question25Desc = props.init && props.init.formTexts.Question12;

  const modules = {
    toolbar: null
  };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{(mode == FormMode.Edit || mode == FormMode.Stage) ? props.steps[props.stepId] : `Stage 2: ${props.steps[props.stepId]}`}</Typography>;
  const aboutOrg = props.init && props.init.formTexts.AboutOrg;
  const aboutOrg2 = `<p>Your responses to questions asked at Stage 1, are displayed here for your reference. They cannot be edited but you can submit supporting documentation at the end of this form should you wish to make us aware of any changes.</p>
  <p>Please remember to click on the information symbol next to each question for guidance on what to include in your response. We strongly encourage you to limit your answers to a maximum of 300 words for each question.
  </p>`;

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }
  const edit = mode == FormMode.Review && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.aboutOrg}>
      {(mode == FormMode.Review) &&
        <List><Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q17.label} ${fieldQ17.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q17} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q18.label} ${fieldQ18.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q18} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q19.label} ${fieldQ19.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q19} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q21.label} ${fieldQ21.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q21} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12} sm={12}>
              {edit}
            </Grid>
          </AccordionDetails>
        </Accordion></List>
      }
       {mode === FormMode.Stage && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: aboutOrg2 }}></div>
          </Grid>
          <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q17.label} ${fieldQ17.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q17} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q18.label} ${fieldQ18.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q18} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q19.label} ${fieldQ19.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q19} modules={modules} /></ListItem></div>
              </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q21.name}
              label={`${q21.label} ${fieldQ21.description} *`}
              quillClass={classesFields.q20}
              fullWidth
              color="secondary" variant="outlined"
              description={ququestion21Desc}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
          </Grid>
        </Grid>
      </>}
      {mode === FormMode.Edit && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: aboutOrg }}></div>
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q17.name}
              label={`${q17.label} ${fieldQ17.description} *`}
              quillClass={classesFields.q8}
              fullWidth
              color="secondary" variant="outlined"
              description={question17Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q18.name}
              label={`${q18.label} ${fieldQ18.description} *`}
              quillClass={classesFields.q8}
              fullWidth
              color="secondary" variant="outlined"
              description={question18Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q19.name}
              label={`${q19.label} ${fieldQ19.description} *`}
              quillClass={classesFields.q8}
              fullWidth
              color="secondary" variant="outlined"
              description={question19Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q21.name}
              label={`${q21.label} ${fieldQ21.description} *`}
              quillClass={classesFields.q20}
              fullWidth
              color="secondary" variant="outlined"
              description={ququestion21Desc}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
            <div style={{ float: 'right', fontWeight: 200, fontSize: '.9em', color: '#0000008a' }}><span>*</span> indicates required</div>
          </Grid>
        </Grid>
      </>}
    </div>
  );
}
