import React from 'react';
import { Grid, Typography, Link, Button, IconButton } from '@material-ui/core';
import { InputField, SelectField, InputAdornmentField, SelectMultipleField, InputRichTextField, CheckboxField } from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { SelectType, TEXT_NONE_OF_THE_ABOVE } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { FormMode } from '../models/FormMode';

export default function PersonalDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    editable,
    formField: {
      salutation,
      firstName,
      lastName,
      jobTitle,
      email,
      confirmEmail,
      phone,
      orgType,
      orgName,
      address,
      postcode,
      website,
      regCharityNumber,
      regCompanyNumber,
      lastFYTurnover,
      charitableWorkArea,
      q16, q17, q18, q19, q21
    },
    getChoices,
    getField,
    mode
  } = props;
  const { values: formValues } = useFormikContext();
  const salutations = getChoices("BGSalutation") && getChoices("BGSalutation");
  const personalIntro = props.init && props.init.formTexts.PersonalIntro;
  const orgTypesChoices = getChoices("BGOrganisationType") && getChoices("BGOrganisationType");
  const charitableWorkAreaChoices = getChoices("BGCharitableWorkArea") && getChoices("BGCharitableWorkArea");
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;
  const fieldSalutaion = props.init && getField("BGSalutation");
  const fieldFirstName = props.init && getField("FirstName");
  const fieldLastName = props.init && getField("BGLastName");
  const fieldJobTitle = props.init && getField("JobTitle");
  const fieldEmail = props.init && getField("BGEmail");
  const fieldPhoneNumber = props.init && getField("BGPhoneNumber");
  const fieldAddress = props.init && getField("BGAddress");
  const fieldPostalCode = props.init && getField("BGPostalCode");
  const fieldOrganisationName = props.init && getField("BGOrganisationName");
  const fieldOrganisationType = props.init && getField("BGOrganisationType");
  const fieldWebsite = props.init && getField("BGWebsite");
  const fieldRegisteredCharityNumber = props.init && getField("BGRegisteredCharityNumber");
  const fieldCharitableWorkArea = props.init && getField("BGCharitableWorkArea");
  const fieldLastFYTurnover = props.init && getField("BGLastFYTurnover");
  const fieldRegisteredCompanyNumber = props.init && getField("BGRegisteredCompanyNumber");
  const fieldQ16 = props.init && getField("FR04Question16");
  const fieldQ17 = props.init && getField("FR04Question17");
  const fieldQ18 = props.init && getField("FR04Question18");
  const fieldQ19 = props.init && getField("FR04Question19");
  const fieldQ20 = props.init && getField("FR04Question20");

  const question16Desc = props.init && props.init.formTexts.Question16;
  const question17Desc = props.init && props.init.formTexts.Question17;
  const question18Desc = props.init && props.init.formTexts.Question18;
  const question19Desc = props.init && props.init.formTexts.Question19;
  const question20Desc = props.init && props.init.formTexts.Question20;
  const lastFYTurnoverDesc = props.init && props.init.formTexts.LastFYTurnover;
  const charitableWorkAreaDesc = props.init && props.init.formTexts.CharitableWorkArea;
  const regCompanyNumberDesc = props.init && props.init.formTexts.RegCompanyNumber;
  const regCharityNumberDesc = props.init && props.init.formTexts.RegCharityNumber;
  const websiteDesc = props.init && props.init.formTexts.Website;
  const orgTypeDesc = props.init && props.init.formTexts.OrgType;
  const orgNameDesc = props.init && props.init.formTexts.OrgName;
  const postcodeDesc = props.init && props.init.formTexts.Postcode;
  const addressDesc = props.init && props.init.formTexts.Address;
  const phoneDesc = props.init && props.init.formTexts.Phone;
  const emailDesc = props.init && props.init.formTexts.Email;
  const emailConfirmDesc = props.init && props.init.formTexts.EmailConfirm;
  const jobTitleDesc = props.init && props.init.formTexts.JobTitle;
  const lastNameDesc = props.init && props.init.formTexts.LastName;
  const firstNameDesc = props.init && props.init.formTexts.FirstName;
  const salutationDesc = props.init && props.init.formTexts.Salutation;

  const modules = {
    toolbar: null
  };

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }
  const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
    <div className={classes.personaldetails}>
      {(mode === FormMode.Review) &&
        <Grid container>
          <Grid item xs={12}>
            <List>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {title}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${salutation.label} ${fieldSalutaion.description}`} secondary={formValues.salutation} /></ListItem>
                      </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${firstName.label} ${fieldFirstName.description}`} secondary={formValues.firstName} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${lastName.label} ${fieldLastName.description}`} secondary={formValues.lastName} /></ListItem>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${jobTitle.label} ${fieldJobTitle.description}`} secondary={formValues.jobTitle} /></ListItem>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${email.label} ${fieldEmail.description}`} secondary={formValues.email} /></ListItem>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${phone.label} ${fieldPhoneNumber.description}`} secondary={formValues.phone} /></ListItem>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${address.label} ${fieldAddress.description}`} secondary={formValues.address} /></ListItem>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${postcode.label} ${fieldPostalCode.description}`} secondary={formValues.postcode} /></ListItem>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${orgName.label} ${fieldOrganisationName.description}`} secondary={formValues.orgName} /></ListItem>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${orgType.label} ${fieldOrganisationType.description}`} secondary={formValues.orgType} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${website.label} ${fieldWebsite.description}`} secondary={formValues.website} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${regCharityNumber.label} ${fieldRegisteredCharityNumber.description}`} secondary={formValues.regCharityNumber} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${regCompanyNumber.label} ${fieldRegisteredCompanyNumber.description}`} secondary={formValues.regCompanyNumber} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${charitableWorkArea.label} ${fieldCharitableWorkArea.description}`} secondary={formValues.charitableWorkArea.join(',')} /></ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${lastFYTurnover.label} ${fieldLastFYTurnover.description}`} secondary={'£' + formValues.lastFYTurnover.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItem button><ListItemText className={classesFields.listItem} primary={`${q16.label} ${fieldQ16.description}`} secondary={formValues.q16?'Yes':'No'} /></ListItem>

                      </Grid>
                      {editable && <>
                        <Grid item xs={12}>
                          <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q17.label} ${fieldQ17.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q17} modules={modules} /></ListItem></div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q18.label} ${fieldQ18.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q18} modules={modules} /></ListItem></div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q19.label} ${fieldQ19.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q19} modules={modules} /></ListItem></div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classesFields.reviewRichText}><ListItem button><ListItemText className={classesFields.listItem} primary={`${q21.label} ${fieldQ20.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q21} modules={modules} /></ListItem></div>
                        </Grid>
                      </>}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {edit}
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </List>
          </Grid>
        </Grid>
      }
      {(mode === FormMode.Stage) &&
        <> {title}
          <List>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${salutation.label} ${fieldSalutaion.description}`} secondary={formValues.salutation} /></ListItem>
              </Grid>

            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${firstName.label} ${fieldFirstName.description}`} secondary={formValues.firstName} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${lastName.label} ${fieldLastName.description}`} secondary={formValues.lastName} /></ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${jobTitle.label} ${fieldJobTitle.description}`} secondary={formValues.jobTitle} /></ListItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${email.label} ${fieldEmail.description}`} secondary={formValues.email} /></ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${phone.label} ${fieldPhoneNumber.description}`} secondary={formValues.phone} /></ListItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${address.label} ${fieldAddress.description}`} secondary={formValues.address} /></ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${postcode.label} ${fieldPostalCode.description}`} secondary={formValues.postcode} /></ListItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${orgName.label} ${fieldOrganisationName.description}`} secondary={formValues.orgName} /></ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${orgType.label} ${fieldOrganisationType.description}`} secondary={formValues.orgType} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${website.label} ${fieldWebsite.description}`} secondary={formValues.website} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${regCharityNumber.label} ${fieldRegisteredCharityNumber.description}`} secondary={formValues.regCharityNumber} /></ListItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${regCompanyNumber.label} ${fieldRegisteredCompanyNumber.description}`} secondary={formValues.regCompanyNumber} /></ListItem>
              </Grid>


              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${charitableWorkArea.label} ${fieldCharitableWorkArea.description}`} secondary={formValues.charitableWorkArea.join(',')} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${lastFYTurnover.label} ${fieldLastFYTurnover.description}`} secondary={'£' + formValues.lastFYTurnover.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText className={classesFields.listItem} primary={`${q16.label} ${fieldQ16.description}`} secondary={formValues.q16} /></ListItem>
              </Grid>
            </Grid>
          </List>
        </>
      }
      {mode == FormMode.Edit &&
        <>
          <Grid item xs={12}>
            {title}
          </Grid>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: personalIntro }}></div>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="selectRadio">
              <SelectField
                name={salutation.name}
                label={`${salutation.label} ${fieldSalutaion.description}`}
                data={salutations}
                fullWidth
                variant="outlined"
                color="secondary"
                selectType={SelectType.RADIO}
                description={salutationDesc}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={firstName.name} disabled={props.disabled} label={`${firstName.label} ${fieldFirstName.description} *`} fullWidth variant="outlined" color="secondary" description={firstNameDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={lastName.name} label={`${lastName.label} ${fieldLastName.description} *`} fullWidth variant="outlined" color="secondary" description={lastNameDesc} />
            </Grid>

            <Grid item xs={12}>
              <InputField name={jobTitle.name} label={`${jobTitle.label} ${fieldJobTitle.description} *`} fullWidth variant="outlined" color="secondary" description={jobTitleDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={email.name} label={`${email.label} ${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary" description={emailDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={confirmEmail.name} label={`${confirmEmail.label} ${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary" description={emailConfirmDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={phone.name} label={`${phone.label} ${fieldPhoneNumber.description} *`} type="text" fullWidth variant="outlined" color="secondary" description={phoneDesc} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputField name={address.name} label={`${address.label} ${fieldAddress.description} *`} fullWidth variant="outlined" color="secondary" description={addressDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={postcode.name} label={`${postcode.label} ${fieldPostalCode.description} *`} fullWidth variant="outlined" color="secondary" description={postcodeDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={orgName.name} label={`${orgName.label} ${fieldOrganisationName.description} *`} fullWidth variant="outlined" color="secondary" description={orgNameDesc} />
            </Grid>
            <Grid item xs={12} className="selectRadio" sm={6}>
              <SelectField
                name={orgType.name}
                label={`${orgType.label} ${fieldOrganisationType.description} *`}
                data={orgTypesChoices}
                fullWidth
                color="secondary"
                selectType={SelectType.RADIO}
                description={orgTypeDesc}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name={website.name} label={`${website.label} ${fieldWebsite.description} *`} fullWidth variant="outlined" color="secondary" description={websiteDesc} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name={regCharityNumber.name}
                label={`${regCharityNumber.label} ${fieldRegisteredCharityNumber.description}`}
                fullWidth
                type="number"
                description={regCharityNumberDesc}
                color="secondary" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name={regCompanyNumber.name}
                label={`${regCompanyNumber.label} ${fieldRegisteredCompanyNumber.description}`}
                fullWidth
                type="number"
                color="secondary" variant="outlined"
                description={regCompanyNumberDesc}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectMultipleField
                name={charitableWorkArea.name}
                label={`${charitableWorkArea.label} ${fieldCharitableWorkArea.description} *`}
                data={charitableWorkAreaChoices.filter((item) => item.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1 && item.indexOf('West Midlands') == -1 && item.indexOf('Wimbledon') == -1 && item.indexOf('City of London') == -1 && item.indexOf('Somerset') != 0)}
                value={charitableWorkArea.value}
                fullWidth
                variant="outlined"
                color="secondary"
                description={charitableWorkAreaDesc}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputAdornmentField
                name={lastFYTurnover.name}
                label={`${lastFYTurnover.label} ${fieldLastFYTurnover.description} *`}
                adornment="£"
                fullWidth
                type="number"
                color="secondary" variant="outlined"
                description={lastFYTurnoverDesc}
              />
            </Grid>
            <Grid item xs={12} >
              <CheckboxField
                className={classesFields.toggleQ16}
                name={q16.name}
                label={`${q16.label} ${fieldQ16.description}`}
                fullWidth variant="outlined" color="secondary" labelPlacement="start"
                description={question16Desc}
              />
            </Grid>
            <Grid item xs={12}>
              <InputRichTextField
                name={q17.name}
                label={`${q17.label} ${fieldQ17.description} *`}
                quillClass={classesFields.q8}
                fullWidth
                color="secondary" variant="outlined"
                description={question17Desc}
              />
            </Grid>
            <Grid item xs={12}>
              <InputRichTextField
                name={q18.name}
                label={`${q18.label} ${fieldQ18.description} *`}
                quillClass={classesFields.q8}
                fullWidth
                color="secondary" variant="outlined"
                description={question18Desc}
              />
            </Grid>
            <Grid item xs={12}>
              <InputRichTextField
                name={q19.name}
                label={`${q19.label} ${fieldQ19.description} *`}
                quillClass={classesFields.q8}
                fullWidth
                color="secondary" variant="outlined"
                description={question19Desc}
              />
            </Grid>
            <Grid item xs={12}>
              <InputRichTextField
                name={q21.name}
                label={`${q21.label} ${fieldQ20.description} *`}
                quillClass={classesFields.q8}
                fullWidth
                color="secondary" variant="outlined"
                description={question20Desc}
              />
            </Grid>
          </Grid>
        </>}
    </div>
  );
}
