import * as Yup from 'yup';
import formModel from './formModel';
const {
  formField: {
    language,
    format,
    consider,
    adjustments,
    adjustmentsTxt,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    regCharityNumber,
    regCompanyNumber,
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    q17,
    q18,
    q19,
    q21,
    div1,
    div2,
    div3,
    div4,
    div5,
    div6,
    div7,
    div7txt,
    attachments
  }
} = formModel;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postcodeRegExp = /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;
const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export default [
  Yup.object().shape({
    [language.name]: Yup.boolean().oneOf([true, false], `${language.requiredErrorMsg}`),
    [format.name]: Yup.boolean().oneOf([true, false], `${format.requiredErrorMsg}`),
    [consider.name]: Yup.boolean().oneOf([true, false], `${consider.requiredErrorMsg}`),
    //[adjustments.name]: Yup.boolean().oneOf([true, false], `${adjustments.requiredErrorMsg}`),
    /* [adjustmentsTxt.name]: Yup.string()
      .when("adjustments", {
        is: true,
        then: Yup.string()
          .required(`This is required`)
          .test('len', `${adjustmentsTxt.requiredErrorMsg}`, val => {
            if (val && val.indexOf('<p><br></p>')) {
              return true;
            }
          }
          )
      }), */
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .required(`${firstName.requiredErrorMsg}`)
      .max(255, `${firstName.label} First Name exceeded limited charcters!`),
    [lastName.name]: Yup.string()
      .max(255, `${lastName.label} Last Name exceeded limited charcters!`)
      .required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string()
      .required(`${jobTitle.requiredErrorMsg}`)
      .max(255, `${jobTitle.label} Job Title exceeded limited charcters!`),
    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email(`Invalid ${email.label} Email Address`),
    [confirmEmail.name]: Yup.string()
      .required(`${confirmEmail.requiredErrorMsg}`)
      .when("email", {
        is: email => (email && email.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("email")], `${email.label} Confirm Email Address doesn't match!`)
      }),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
      .matches(phoneRegExp, `Invalid ${phone.label} Phone number!`)
      .test('len', `Invalid ${phone.label} Phone number!`, val => val && val.length === 11),
    [orgName.name]: Yup.string()
      .required(`${orgName.requiredErrorMsg}`)
      .max(255, `${orgName.label} Organisation name exceeded limited charcters!`),
    [orgType.name]: Yup.string().required(`${orgType.requiredErrorMsg}`),

    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [postcode.name]: Yup.string()
      .required(`${postcode.requiredErrorMsg}`)
      .matches(postcodeRegExp, `Invalid ${postcode.label} Postcode`),
    [website.name]: Yup.string()
      .required(`${website.requiredErrorMsg}`)
      .matches(urlRegExp, `${website.label} Website must start with either http or https`),
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
    [lastFYTurnover.name]: Yup.string()
      .required(`${lastFYTurnover.requiredErrorMsg}`),
    [regCharityNumber.name]: Yup.number()
      .when(regCompanyNumber.name, {
        is: (val) => !val || val <= 0,
        then: Yup.number()
          .required(`${regCharityNumber.requiredErrorMsg}`)
          .positive(() => `Invalid Registered Charity Number`)
      }),
    [regCompanyNumber.name]: Yup.number()
      .when(regCharityNumber.name, {
        is: (val) => !val || val <= 0,
        then: Yup.number()
          .required(`${regCompanyNumber.requiredErrorMsg}`)
          .positive(() => `Invalid Registered Company Number`)
      }),
    [q17.name]: Yup.string()
      .required(`${q17.requiredErrorMsg}`)
      .test('len', `${q17.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q18.name]: Yup.string()
      .required(`${q18.requiredErrorMsg}`)
      .test('len', `${q18.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q19.name]: Yup.string()
      .required(`${q19.requiredErrorMsg}`)
      .test('len', `${q19.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q21.name]: Yup.string()
      .required(`${q21.requiredErrorMsg}`)
      .test('len', `${q21.requiredErrorMsg}`, val => {
        if (val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),

  },
    [[regCharityNumber.name, regCompanyNumber.name]]
  ),
  Yup.object().shape({
    // [div1.name]: Yup.string()
    //   .required(`${div1.requiredErrorMsg}`)
    //   .test('len', `${div1.formatError}`, val => {
    //     //if (val && val.indexOf(';') > -1) {
    //     return true;
    //     //}
    //   }),
    // [div2.name]: Yup.array().min(1, `${div2.requiredErrorMsg}`),
    // [div3.name]: Yup.string()
    //   .required(`${div3.requiredErrorMsg}`)
    //   .test('len', `${div3.formatError}`, val => {
    //     // if (val && val.indexOf(';') > -1) {
    //     return true;
    //     // }
    //   }),
  }),
  Yup.object().shape({
    // [div4.name]: Yup.array().min(1, `${div4.requiredErrorMsg}`),
    //  [div5.name]: Yup.array().min(1, `${div5.requiredErrorMsg}`),
    //  [div6.name]: Yup.array().min(1, `${div6.requiredErrorMsg}`),
    [div7.name]: Yup.boolean().oneOf([true, false], `${div7.requiredErrorMsg}`),
    [div7txt.name]: Yup.string()
      .when("div7", {
        is: true,
        then: Yup.string()
          .required(`This is required`)
          .test('len', `${div7txt.requiredErrorMsg}`, val => {
            if (val && val.indexOf('<p><br></p>')) {
              return true;
            }
          }
          )
      }),
  }),
  Yup.object().shape({
    //[attachments.name]: Yup.array().min(1, `${attachments.requiredErrorMsg}`),
  })
];
